import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Software/Windows/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Windows Software for your INSTAR IP Camera",
  "path": "/Software/Windows/Zebra_Surveillance/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "The Windows app Zebra for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features.",
  "image": "./P_SearchThumb_Zebra_Surveillance.png",
  "social": "/images/Search/P_SearchThumb_Zebra_Surveillance.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-Zebra-Surveillance_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Zebra Surveillance' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='The Windows app Zebra for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features.' image='/images/Search/P_SearchThumb_Zebra_Surveillance.png' twitter='/images/Search/P_SearchThumb_Zebra_Surveillance.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/Zebra_Surveillance/' locationFR='/fr/Software/Windows/Zebra_Surveillance/' crumbLabel="Zebra Surveillance" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#windows",
        "aria-label": "windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows`}</h2>
    <h3 {...{
      "id": "zebra-surveillance",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#zebra-surveillance",
        "aria-label": "zebra surveillance permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Zebra Surveillance`}</h3>
    <p>{`ZM Surveillance System is a video surveillance and motion capture software. Some key features are: unlimited cameras, motion detection, SMS and MMS and Email alerts, play sound file (useful on motion detection), adjustable sensitivity for each zone, record on detection, save video into archive or file with automatically or manually selected compression, record on schedule, registration of all events continuously by intervals or on motion detected, password protection, adding a text, time stamps, watermarks to your video. For each video frame, if motion is detected, the Alarm event occurs, that returns a global motion ratio, depending of the number of cells in which motion has been detected, and the level of motion in each cell.`}</p>
    <p>{`In order to integrate your INSTAR IP camera in the `}<a href="http://www.zebra-media.com/zebra_media_surveillance_system.html" target="_blank" rel="noopener noreferrer">{`ZM Surveillance System`}</a>{` we recommend you to download the latest version from `}<a href="http://www.zebra-media.com/zebra_media_surveillance_system.html/" target="_blank" rel="noopener noreferrer">{`here`}</a>{`.`}</p>
    <h2 {...{
      "id": "add-a-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#add-a-camera",
        "aria-label": "add a camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a Camera`}</h2>
    <p>{`Click on the Add Camera button to open the camera wizard. The video path to your camera consist of the local IP address and the login credentials. The video path would be:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`VGA Video Path: http://192.168.1.237:80?user=admin&pwd=`}</code></pre></div>
    <p>{`for a camera with the default login user = admin, password = none, the local IP address 192.168.1.237 and the HTTP port 80. Please adjust the path if you changed the default login or the default web port accordingly. For an HD camera with the default login user = admin and password = instar, the local IP address 192.168.1.175 and the HTTP port 85:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`HD Video Path: http://192.168.1.237:80/tmpfs/snap.jpg`}</code></pre></div>
    <p>{`It is possible to use an external (very expensive) plugin to connect to the camera´s RTSP video stream instead of the JPG refresh mode. Please refer to the instructions given on the official website if you need to use the HD video stream.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0e94dce31bb09491cb9ab0f69b354c10/4dba2/Zebra-Media-Surveillance-System_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRElEQVQoz8WRyU7CYBSF+2ZIB5EYYnwEw9aFC98BGX5oS0sn/rYyQwcCbTGh/Yf6ZAZJwCERogtPvpy7Ojk39zJ31er9w2Pl5pZlOZYTeKHE8QLP80KpXCpXLq+ui5xQKBQLF18pshyT0nwwnRvQtqCrQajCfs+0LUvSdFmzoKL324pqQMe0XdN2PmJAh3GcgSirDSC2OhLodJutNujIqqprmmEYlq6bpgltd+g8jw7Y7s7dwZiZTee+vxxN/cnMG0688XgxX4RBGEVRsklekngTx0kQrv1g7Qcrz1957x4uoyiKGYTQ6075wfM8p2cIY8ykaUopJYRgTMhxnBZC6Bj+rn3DL8MIoSzL/iP8p7XPOlie08+FZ4e323T3HUwJphiTPYTQkyCEGUmUa7UGAFK9AQAQW832U73ZlXuaovd+RFW0N0hrN1/pVm3rAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e94dce31bb09491cb9ab0f69b354c10/e4706/Zebra-Media-Surveillance-System_01.avif 230w", "/en/static/0e94dce31bb09491cb9ab0f69b354c10/d1af7/Zebra-Media-Surveillance-System_01.avif 460w", "/en/static/0e94dce31bb09491cb9ab0f69b354c10/7f308/Zebra-Media-Surveillance-System_01.avif 920w", "/en/static/0e94dce31bb09491cb9ab0f69b354c10/3c69e/Zebra-Media-Surveillance-System_01.avif 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0e94dce31bb09491cb9ab0f69b354c10/a0b58/Zebra-Media-Surveillance-System_01.webp 230w", "/en/static/0e94dce31bb09491cb9ab0f69b354c10/bc10c/Zebra-Media-Surveillance-System_01.webp 460w", "/en/static/0e94dce31bb09491cb9ab0f69b354c10/966d8/Zebra-Media-Surveillance-System_01.webp 920w", "/en/static/0e94dce31bb09491cb9ab0f69b354c10/ee998/Zebra-Media-Surveillance-System_01.webp 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e94dce31bb09491cb9ab0f69b354c10/81c8e/Zebra-Media-Surveillance-System_01.png 230w", "/en/static/0e94dce31bb09491cb9ab0f69b354c10/08a84/Zebra-Media-Surveillance-System_01.png 460w", "/en/static/0e94dce31bb09491cb9ab0f69b354c10/c0255/Zebra-Media-Surveillance-System_01.png 920w", "/en/static/0e94dce31bb09491cb9ab0f69b354c10/4dba2/Zebra-Media-Surveillance-System_01.png 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0e94dce31bb09491cb9ab0f69b354c10/c0255/Zebra-Media-Surveillance-System_01.png",
              "alt": "Windows App Zebra Surveillance",
              "title": "Windows App Zebra Surveillance",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the Add Camera button to get started.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/74539c2cbf82f23edd8e0d0f5e721d4d/38124/Zebra-Media-Surveillance-System_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAADR0lEQVQoz0WMWU8bVwBG539UqtSqTVED2MQLiomNd0OJvLAJ7Hgjax8i9aHrS5tISZU0KBElA8GMl5m593qWu8yMl9JgXBsLBbM8JPQHVahSK306Oi/n47K5YuZWLpFcTKSWEqnl5PxyIrWYSC6kFi5leSWdzxdz/y5XzGYL2WwhlyvkC2vF4h2utCMgqFSqogQQQBqqawDoEBCECISYEtM0LEYMRi+lYTUbVtOgFiXMMhqcsFsRZSABoGgaUlVNx6piQsAQMOrQwBqFQJFECOS6LCEA6rIMJRFKEtRUzBnUPBuenxyfDd+dnA5PP7y/OD05M5nZsBqWYTXMZrv1R7u112q0KWYqUk1mtZrtdqvdtJqcqujt1h7WKNEZwZRgqmtEFqEsgktKECEFa4ToTFP0OlQIZhQzRk2Dmly79edBpyeKoI40COqVSq1SrgpCZXe3LIoyhIosQ03VsU51nRDMVFXXVIygCqDKVctVXcMKUpW6ijUCJKggldHLe1iTQbUGRVkoCUJJqJZrCCCKGdYII4bFLI5/vVWHSNd0ggmjhiBUAajz/JtvHny9lJhJ3vwqGgqFAv5YNBYJR26tpPnNLYQUqrP9vX3u7dvOxYe/L95fnJ+ftxqtakUURfne3XvRSDgSDvp8PpfzmmfSPRuYDvj9k+7J+/cfvHi+LuwI3f0u12y1hyfD4+Pjo6OjhtXc5Ldfb/Kx6em4PxANBr1ebzwe9/m8Ed/11Uw6Fg75pqYC0/715y/63T6nY9zr9TqdTr/fNw3r2+9/+u6HH+OJhdjM3HWPJ1+8s3b3oefG1DX3xPJqejYSTrrcbqfz6ZNfD/865Aih/X6/2+0eDg4ZZXM347HYTHK1MJeYDwYDqYWlTCYT8t8YH/vM4bRHfb7ZcZvjy6uPHj8Z/BcfHBwMBgOCyciVEfvYmM/tctnsdpvdaRt3220To6Mu2+gnH380MfKF/erolU8//+Xnx4PegCP0/5hhGo9Eb68V14r5fDadyawsLc57vFMTDofL4YyFgxG/Izw9Nhuyrz971uv2OZ7fhgBJoizWpFpNLO0KtWpNVVSEEASwLAhb/NbOm9I2z4ui9HLj1carl789ffT7xqaqkn8AnPbkKRoswrgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/74539c2cbf82f23edd8e0d0f5e721d4d/e4706/Zebra-Media-Surveillance-System_02.avif 230w", "/en/static/74539c2cbf82f23edd8e0d0f5e721d4d/d1af7/Zebra-Media-Surveillance-System_02.avif 460w", "/en/static/74539c2cbf82f23edd8e0d0f5e721d4d/7f308/Zebra-Media-Surveillance-System_02.avif 920w", "/en/static/74539c2cbf82f23edd8e0d0f5e721d4d/e6eb8/Zebra-Media-Surveillance-System_02.avif 953w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/74539c2cbf82f23edd8e0d0f5e721d4d/a0b58/Zebra-Media-Surveillance-System_02.webp 230w", "/en/static/74539c2cbf82f23edd8e0d0f5e721d4d/bc10c/Zebra-Media-Surveillance-System_02.webp 460w", "/en/static/74539c2cbf82f23edd8e0d0f5e721d4d/966d8/Zebra-Media-Surveillance-System_02.webp 920w", "/en/static/74539c2cbf82f23edd8e0d0f5e721d4d/5bf28/Zebra-Media-Surveillance-System_02.webp 953w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/74539c2cbf82f23edd8e0d0f5e721d4d/81c8e/Zebra-Media-Surveillance-System_02.png 230w", "/en/static/74539c2cbf82f23edd8e0d0f5e721d4d/08a84/Zebra-Media-Surveillance-System_02.png 460w", "/en/static/74539c2cbf82f23edd8e0d0f5e721d4d/c0255/Zebra-Media-Surveillance-System_02.png 920w", "/en/static/74539c2cbf82f23edd8e0d0f5e721d4d/38124/Zebra-Media-Surveillance-System_02.png 953w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/74539c2cbf82f23edd8e0d0f5e721d4d/c0255/Zebra-Media-Surveillance-System_02.png",
              "alt": "Windows App Zebra Surveillance",
              "title": "Windows App Zebra Surveillance",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Type in your camera´s video path and click to Start the preview.`}</p>
    <h3 {...{
      "id": "video-recording",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#video-recording",
        "aria-label": "video recording permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Video Recording`}</h3>
    <p>{`The Video Recording tab allows you to make adjustments to the recording parameters as well as setting up an activity schedule for your camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ad9e1f5851bfa1ef197d1236ecc03320/eb2af/Zebra-Media-Surveillance-System_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAADd0lEQVQoz13N3U9adwDG8fPP7GIXzRLTJu06Czp56WJ1hSogiLq26QC1ttgWSOqAqc3SbEmTJb1otibrxZpqt2paxGa6USYSDxYUOIcD5/UH5wUCAoeXogPPsnVXS56L780nD+S6673j+mpmZtZuvzU5bbdO3bRO3rDaZmzX7VPTN50O19cer8fteT+v+7/2erwL84tQKLQTiyF4hs4BjqY5mmJpkqVIlmHYGAr28UKKqaJ0BQOVFFNK0aU0qGSAmAEizpShRBxFERxDcZIA1QOxXm3Uq41apXFUb/yWEAPpMinwGMfDVAXJ1VG2HiYrW2kcJvEtjIFgOBrfR5EkRhGAIgCgWUCzNJWrlcprMTGMoVLL1xJ9VA6vlRutanMfUC+Ihy/pB/7UKoTE0aJQZBmWIWiWYYtCsZQviQdiq1Zbj9Uevlhfhqefxx2P15589+rqT4j1eXA58PO3u8/m/tjwQYl9hAc8hdMsYLN0DlAgx7DFfOmoVvftNUIYdny08tehfxeHYcaPlNf94eDm8je7q3MbmytQJBLF03g6laZJJge4TJqgCEZgCwWuENnF9mJRgEWS0TextzEcyfJ4MccV9rMxRIgTPAHBOxFAgzyfz/NCnisk4imCYMJh+Pv7929ZJyavXTEZTHqdfmzUPGIwOGZnt//czlJ8iTtoN9vQDhzJgixF0q3W4bvGu0QihSCphYVFk9FoHDFotBqV8tOB86oJ3dCwblitPr+weG/tpT8aeds+bEMkRTWbzZpY6xx3xIoYCsPB4NaY9pJFpx/V6TVazZcWi1Z70aTpd7ic4yajdnBwSDv0+pVfOpYgQShIktTpdCRJKhVLjx4/efTDjxbbdfP45f6Bfrd30XvvwYXPB3rVPfY7zi9GjTal+jOFcuXXVUmSII4XJElqt9uSJBXyhYkrV81ms/W2+/K1Sb1+2DY143Q4DJcGZZ981KuQjWo0493nFKdPP1v65R/M/4vfPwscf7LrlLy7W6NSKmUy+Tm5QiZTy2W9Zz9Wyc6e+PCDnlMn5WfOdJ3oevp06f+4wAkW89j8vHfeM+e+63Q5b9+Ymb5wcbCnT6HqU4yZ9KahvhFt98SI/PWaT+pIUDgE42kyhaZRBEOSqWh0L5lEGJohCBLPZPZie+HtcASO7Gxvoyi6EdgM/L6xvrr0JhAEgP8byiJyR9zC1bIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ad9e1f5851bfa1ef197d1236ecc03320/e4706/Zebra-Media-Surveillance-System_03.avif 230w", "/en/static/ad9e1f5851bfa1ef197d1236ecc03320/d1af7/Zebra-Media-Surveillance-System_03.avif 460w", "/en/static/ad9e1f5851bfa1ef197d1236ecc03320/7f308/Zebra-Media-Surveillance-System_03.avif 920w", "/en/static/ad9e1f5851bfa1ef197d1236ecc03320/4adfd/Zebra-Media-Surveillance-System_03.avif 954w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ad9e1f5851bfa1ef197d1236ecc03320/a0b58/Zebra-Media-Surveillance-System_03.webp 230w", "/en/static/ad9e1f5851bfa1ef197d1236ecc03320/bc10c/Zebra-Media-Surveillance-System_03.webp 460w", "/en/static/ad9e1f5851bfa1ef197d1236ecc03320/966d8/Zebra-Media-Surveillance-System_03.webp 920w", "/en/static/ad9e1f5851bfa1ef197d1236ecc03320/d7803/Zebra-Media-Surveillance-System_03.webp 954w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ad9e1f5851bfa1ef197d1236ecc03320/81c8e/Zebra-Media-Surveillance-System_03.png 230w", "/en/static/ad9e1f5851bfa1ef197d1236ecc03320/08a84/Zebra-Media-Surveillance-System_03.png 460w", "/en/static/ad9e1f5851bfa1ef197d1236ecc03320/c0255/Zebra-Media-Surveillance-System_03.png 920w", "/en/static/ad9e1f5851bfa1ef197d1236ecc03320/eb2af/Zebra-Media-Surveillance-System_03.png 954w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ad9e1f5851bfa1ef197d1236ecc03320/c0255/Zebra-Media-Surveillance-System_03.png",
              "alt": "Windows App Zebra Surveillance",
              "title": "Windows App Zebra Surveillance",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Adjust the video recording parameter and set-up an activity schedule for your camera.`}</p>
    <h3 {...{
      "id": "motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#motion-detection",
        "aria-label": "motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Motion Detection`}</h3>
    <p>{`The ZM Surveillance System allows you to draw a motion detection grid over the cameras live view. Adjust the cell size as well as the cell sensitivity to your needs. The higher the number inside a cell, the higher the sensitivity will be in this area. You can further except reds, greens and blues from the motion detection, or just use a monochrome image to further reduce false alerts.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0bc60839e31c6793ac388070403b9873/eb2af/Zebra-Media-Surveillance-System_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAADfElEQVQozy2Q/U8adxzH7+9Ztv2wJVvm4tySmTauy0gfaKv1qWgRXR+2Kq7t3FxXrOniw9S5zro+xyVrRCgFtWJtBdRFngTuGTjujoMDBL1DtAfcfRfWJe/k/fnl9Xl/3h/IZLaZzLZZo8U4ZzWZ523WxWdP5+eMVpPZZjRZl5ZW1lwbLse6y7HuXF2ryLHu/G9efemEfL6g1xtCEBLHo1EqEY2wcBBD0Uic5TN8VsiKe0I+L4iiIBZfFwv5gnTwuihJpWLpYP8AoimGjcU5mtvbEbnkLsuk4jRHElESJUmUxGE8FIBhGIFhhEsk9goFqSiVy2VZUSRJgpAQHqPYBMsnubTPG0Rh8oX91dQf07dv35mY+H1ycmpsbHJsfPLWL8N37z3I5XKyLCuKAgCowFveIIaGCYKqXI5FSDzq9wYILIwj+NxfMzP3H0zf+fPu9D3PppeJxZMcL4qiKOSFSpE85IcD4QhFU3EqwtCxOMskQiHUvekbGRo+p2mtP3Wyvbmp6UzDyPDoC/tLhuZIMroVCJJ4hOdSkA/2YwROYASBExEyEgrA/2xsLi4u9XX3nO/o1PdeuXC+63r/j11t7YOGQYqkGJolI+EMv51N5yA0hDE0k+JTqVSK53gEIZ2O9eHR8av9hgsXL94cHNJoWnr03d0trT2Xvu278r3LsUZFmWSclwoSBIcQOkZHo5QkSaKQx7Dw80W76iuV7lxHa1Nz+9mzp9VHVXWHGlVHdVrd8eMnbhgMNsuC3+2XJRnKZDIH+/uFwj4AIJfdcbo2VpZXBn66Pj46cmtwoFffo9V2fHf5kv4bzc2fr3VptaojR04cU9vnnwMZQPl8HgDw5vuZ9Pb4b1OrjrWCWBBygpgTsqntTDK9m93dSWfcm25dm6az9lDd4cOWp1YAACSKIgCgXC4DAPgkrz51+texif/XKaAiABS5YjRNt9U3NH9cXVtVNWs0V+A3ybIsAwDibPydt95uqD/zzGyxzBof3n/0+OHMo8czs0+MSwvLo0MjtVUfVX/w4fvvvvfkb2MF3t0VFEUplUoAAI5mO5tbBgw3+n+41ndV36u//HWXru7LL6o/rfnsk5qGk8ca1bVN6pq2xs/t8wuVzgE/TGBhDCVQBIdhNBAMETiR4DiapmMUBYdgj9vj92/5PZ5wOLzqcrocr5ZtJqfDRce4fwHU1550NvEXLwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0bc60839e31c6793ac388070403b9873/e4706/Zebra-Media-Surveillance-System_04.avif 230w", "/en/static/0bc60839e31c6793ac388070403b9873/d1af7/Zebra-Media-Surveillance-System_04.avif 460w", "/en/static/0bc60839e31c6793ac388070403b9873/7f308/Zebra-Media-Surveillance-System_04.avif 920w", "/en/static/0bc60839e31c6793ac388070403b9873/4adfd/Zebra-Media-Surveillance-System_04.avif 954w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0bc60839e31c6793ac388070403b9873/a0b58/Zebra-Media-Surveillance-System_04.webp 230w", "/en/static/0bc60839e31c6793ac388070403b9873/bc10c/Zebra-Media-Surveillance-System_04.webp 460w", "/en/static/0bc60839e31c6793ac388070403b9873/966d8/Zebra-Media-Surveillance-System_04.webp 920w", "/en/static/0bc60839e31c6793ac388070403b9873/d7803/Zebra-Media-Surveillance-System_04.webp 954w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0bc60839e31c6793ac388070403b9873/81c8e/Zebra-Media-Surveillance-System_04.png 230w", "/en/static/0bc60839e31c6793ac388070403b9873/08a84/Zebra-Media-Surveillance-System_04.png 460w", "/en/static/0bc60839e31c6793ac388070403b9873/c0255/Zebra-Media-Surveillance-System_04.png 920w", "/en/static/0bc60839e31c6793ac388070403b9873/eb2af/Zebra-Media-Surveillance-System_04.png 954w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0bc60839e31c6793ac388070403b9873/c0255/Zebra-Media-Surveillance-System_04.png",
              "alt": "Windows App Zebra Surveillance",
              "title": "Windows App Zebra Surveillance",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Adjust the motion detection sensitivity by adjusting the numbers inside the detection grid.`}</p>
    <h3 {...{
      "id": "alerts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alerts",
        "aria-label": "alerts permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alerts`}</h3>
    <p>{`When an alarm condition is detected, the program can sound an audible alarm, or send you an email with a photo. Automatically capture photos, or record video in compressed files. The Alerts section allows you to define right action for the trigger event. Click Save to finish the camera installation and start using the ZM Surveillance System.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/51adb734e798161f075b18b18f2e5bf1/38124/Zebra-Media-Surveillance-System_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAADR0lEQVQozz3PS28bZRQG4PlDFYIVstSqrRRRWASJtrRFVKCQoggqEAs2SFDWFatKbSTShIYscJPYUe0muLGbpI7t1GmdjO0Z2zPjuX6eu+c+4/F1PuRU4ujdHT16z0FS6Uz6+YtEMp3c2t7c2k4+2/l3J7eX3c/mDjK7+8Vi+fSkelJBTyposfD6MF88zJfyr4r5g8Kr/UPk+PikXD7FMYIkWZLkm016M5FaW3u6uhZ/svb0n3gimUwnEqlkMlWr4gzFUQTD0QLHCJqsIw2s2SbaEpB4hiebFFZrrKysPlr888GDR0uP/1pe+fvx8pOl5dWHi0voSRWwAPAiRbKa0rV0C9EVfRyOJuGYBwLWbPAs6AiSpVth0I/GUeiHnuP3gj4QFJEXDc3wbI8iaUXS7K6NCCzQFV0VVVVWNVkTWEBTrChIjumEfmgZVlczLMM2NZNnBLkjm7rJMYIsnmFN1gInMHWz5/W6qkmRDM8KpmpIQBI4gWyRZItSJMXUTBnIjuH0bA+QjKYa07NJog04IJxFERWyReFYs6sYAg8IgqRphqaZdpvWFR2tVFmSUWVNAnJXNUzNRHCswTGcIinvUqlUmw0KcCJLCzzbETgRCLKuGNU6u7Dw/c8//nRUKtNtTu6og2CA4HhDEADLcIPBMPACFK23SUZR9DCYPmx1Tdu0+72w09Hm5+/MzMzcv//Hi51dtIJOBhNE0/QgCHzfj6LItpy9gzyOEaKoBH4Q+EEv6AX+dOs57m+/37t96+bV2U8/v3Yjl8nCCUQ8z4MQRlEEIdQ1/eHiEl5rhH44Go5Gw9FwOBwOhuPR2LHdX36999UXN+5e+fiTj648T21DCBHXdSGE4/EYQih2pLn5b+soNhqMRmd2dDZRFBmGee361Zuzs3MXLs7EYonks3d42jyZTCCEPMvHPozVUdy1A9fy+r2+a/uuHTima5nu5YvnY++duxSLfXDu/Y315BQ7jvs/7nDg7tdzuWyufHT8tvzmzetyOpWOx9c31pOb8Y0fvlu49dml29cvfPPl5VwmA8cQqVdxiqCJFtVqko1Gq1arV95WcAyrVavo6elRqZTLZl++3Nvb3W0RrXwxX8jv53a2CoUSAPJ/YvLcpEyL2AYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/51adb734e798161f075b18b18f2e5bf1/e4706/Zebra-Media-Surveillance-System_05.avif 230w", "/en/static/51adb734e798161f075b18b18f2e5bf1/d1af7/Zebra-Media-Surveillance-System_05.avif 460w", "/en/static/51adb734e798161f075b18b18f2e5bf1/7f308/Zebra-Media-Surveillance-System_05.avif 920w", "/en/static/51adb734e798161f075b18b18f2e5bf1/e6eb8/Zebra-Media-Surveillance-System_05.avif 953w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/51adb734e798161f075b18b18f2e5bf1/a0b58/Zebra-Media-Surveillance-System_05.webp 230w", "/en/static/51adb734e798161f075b18b18f2e5bf1/bc10c/Zebra-Media-Surveillance-System_05.webp 460w", "/en/static/51adb734e798161f075b18b18f2e5bf1/966d8/Zebra-Media-Surveillance-System_05.webp 920w", "/en/static/51adb734e798161f075b18b18f2e5bf1/5bf28/Zebra-Media-Surveillance-System_05.webp 953w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/51adb734e798161f075b18b18f2e5bf1/81c8e/Zebra-Media-Surveillance-System_05.png 230w", "/en/static/51adb734e798161f075b18b18f2e5bf1/08a84/Zebra-Media-Surveillance-System_05.png 460w", "/en/static/51adb734e798161f075b18b18f2e5bf1/c0255/Zebra-Media-Surveillance-System_05.png 920w", "/en/static/51adb734e798161f075b18b18f2e5bf1/38124/Zebra-Media-Surveillance-System_05.png 953w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/51adb734e798161f075b18b18f2e5bf1/c0255/Zebra-Media-Surveillance-System_05.png",
              "alt": "Windows App Zebra Surveillance",
              "title": "Windows App Zebra Surveillance",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Define an action response to an alarm trigger event.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bd2f4eb251bb684c3f3aca307bdc27ca/33d1d/Zebra-Media-Surveillance-System_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAACSklEQVQoz42R20/TcBTH+1+pL0ZnGFkiBDBRDFFhSjQYfVBIJKKJbE7qNMEwcGMTLw+ABlmAqezae7t27D7GxK2FwQgYsIQIrFu7nxmXF3XKyeck5+WTb845UJ+pf+jtSJ/ppcEAG548heFnPT0wXG7YaHwOw0b9Y8Ojbn237nd0Oj3kxnA2HENozotQPozwsn6ECFC0E8fdKBVwE9yUF8X9MyQXJNgyJHswEGwQIimaJCkEw30IihEkhmIkSYVCoUg4nIhHY7FoMjmbzvBpXkjzQiZTJp0ReF7ghQUIYxKvJ6KmD2Hr5KxtMmkZi775NPfey0+QWTvKj3m/jbq+Wu0Jy3h80B63jMes9rj5Y3zQnhhxpaDNrZ2tPOAi8wO2kV7zO5Nt2Dw02m8bHng1msysbPxUVsXdNTG/Jua/77F2yOrGDlSQJABAdmFxhmVZPxcLR1Ozyfm5VDgYEkURAFAqlcBfq6RA0p68vr6B+tAXsKGr815He7u+Wx8JRbe3dwEAilLW/6QoywdyNrt0p+1mfU2NRlPdWFfTUF/Xdf+BKG7+I1mWZSifzwMAeGHhmrZV29zS0fmwsenC7RvXL9fWLudWjibzQtMlbX3DOe2V5jOnjqtOHLuo1iwt544q373V1nq1pVpdVXVadVZ98nydankpuycrFeX9nRdzi8FIHKUjPpTEcYpmWMTzRRR//DdZ2r+eVChKhaIilwoFuVhUFAVU+tGhrEAoQkw5nB4v/nna4/agrmmPw+EkcIahWIbm/HSgEgzF/QJULKWiNFiaJAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bd2f4eb251bb684c3f3aca307bdc27ca/e4706/Zebra-Media-Surveillance-System_06.avif 230w", "/en/static/bd2f4eb251bb684c3f3aca307bdc27ca/d1af7/Zebra-Media-Surveillance-System_06.avif 460w", "/en/static/bd2f4eb251bb684c3f3aca307bdc27ca/7f308/Zebra-Media-Surveillance-System_06.avif 920w", "/en/static/bd2f4eb251bb684c3f3aca307bdc27ca/d31ba/Zebra-Media-Surveillance-System_06.avif 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bd2f4eb251bb684c3f3aca307bdc27ca/a0b58/Zebra-Media-Surveillance-System_06.webp 230w", "/en/static/bd2f4eb251bb684c3f3aca307bdc27ca/bc10c/Zebra-Media-Surveillance-System_06.webp 460w", "/en/static/bd2f4eb251bb684c3f3aca307bdc27ca/966d8/Zebra-Media-Surveillance-System_06.webp 920w", "/en/static/bd2f4eb251bb684c3f3aca307bdc27ca/7ed5b/Zebra-Media-Surveillance-System_06.webp 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bd2f4eb251bb684c3f3aca307bdc27ca/81c8e/Zebra-Media-Surveillance-System_06.png 230w", "/en/static/bd2f4eb251bb684c3f3aca307bdc27ca/08a84/Zebra-Media-Surveillance-System_06.png 460w", "/en/static/bd2f4eb251bb684c3f3aca307bdc27ca/c0255/Zebra-Media-Surveillance-System_06.png 920w", "/en/static/bd2f4eb251bb684c3f3aca307bdc27ca/33d1d/Zebra-Media-Surveillance-System_06.png 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bd2f4eb251bb684c3f3aca307bdc27ca/c0255/Zebra-Media-Surveillance-System_06.png",
              "alt": "Windows App Zebra Surveillance",
              "title": "Windows App Zebra Surveillance",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Start using the ZM Surveillance System.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      